import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
} from "core/consts";
import {
  PATIENT_DOCUMENTS,
  postPatientDocument,
} from "core/model/careseekers/patientDocumentTransfer";
import { bytesToBase64 } from "core/model/crypto";
import { openPdfInNewWindow } from "core/model/utils/urls";
import { Careseeker, Patient, QueryProgressActive } from "core/types";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { CreateRehaPdfProps } from "dsl/ecosystems/PatientForms/useCreateRehaPdf";
import { CreateTransitionalCarePdfProps } from "dsl/ecosystems/PatientForms/useCreateTransitionalCarePdf";
import { useCallback, useState } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

export function useDocumentHandling<
  T extends CreateTransitionalCarePdfProps | CreateRehaPdfProps,
>({
  careseekerName,
  createPDFDocument,
  externalId,
  userId,
}: {
  careseekerName: NonNullable<Careseeker["name"]>;
  createPDFDocument: (data: T) => Promise<Uint8Array>;
  externalId: NonNullable<Patient["user_id"]>;
  userId: NonNullable<Patient["user_id"]>;
}) {
  const toast = useToast();
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();
  const [progressOpenPdf, setProgressOpenPdf] = useState<QueryProgressActive>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [progressSendToHis, setProgressSendToHis] =
    useState<QueryProgressActive>(QUERY_PROGRESS_NOT_STARTED);

  const handleOpenPdf = useCallback(
    async (data: T) => {
      if (!data) return;
      try {
        setProgressOpenPdf(QUERY_PROGRESS_PENDING);
        const pdfBytes = await createPDFDocument(data);
        openPdfInNewWindow(pdfBytes);
      } catch (err) {
        console.error((err as Error).message);
        // Optional: toast message for error
      } finally {
        setProgressOpenPdf(QUERY_PROGRESS_NOT_STARTED);
      }
    },
    [
      careseekerName,
      createPDFDocument,
      externalId,
      openPdfInNewWindow,
      setProgressOpenPdf,
      userId,
    ],
  );

  const handleSendToHis = useCallback(
    async (data: T) => {
      if (!data) return;
      try {
        if (!on_premise_domain_ssl) {
          throw new Error("'on_premise_domain_ssl' is required");
        }
        setProgressSendToHis(QUERY_PROGRESS_PENDING);
        const pdfBytes = await createPDFDocument(data);
        const encodedPdf = bytesToBase64(pdfBytes);
        await postPatientDocument({
          careseekerName,
          documentType: PATIENT_DOCUMENTS.TRANSITIONAL_CARE,
          encodedPdf,
          externalId,
          on_premise_authorization_token,
          on_premise_domain_ssl,
          targetHTML: encodedPdf,
          trackEvent,
          userId,
        });
        toast({
          color: "success",
          message:
            translations.patientForms.transitionalCareForm.downloadSection
              .hisSuccess,
        });
      } catch (err) {
        console.error((err as Error).message);
        toast({
          color: "danger",
          message:
            translations.patientForms.transitionalCareForm.downloadSection
              .hisError,
        });
      } finally {
        setProgressSendToHis(QUERY_PROGRESS_NOT_STARTED);
      }
    },
    [
      bytesToBase64,
      careseekerName,
      createPDFDocument,
      externalId,
      on_premise_authorization_token,
      on_premise_domain_ssl,
      postPatientDocument,
      setProgressSendToHis,
      toast,
      trackEvent,
      translations,
      userId,
    ],
  );

  return {
    progressOpenPdf,
    progressSendToHis,
    handleOpenPdf,
    handleSendToHis,
  };
}
