import { TransitionalCare } from "core/types";
import { DownloadSectionWrapper } from "dsl/ecosystems/PatientForms/components";
import {
  DOWNLOADED_PDF_NAME,
  useCreateTransitionalCarePdf,
} from "dsl/ecosystems/PatientForms/useCreateTransitionalCarePdf";
import { useTranslations } from "translations";
import { useDocumentHandling } from "../../useDocumentHandling";

export const FormDownload = ({
  careseekerName,
  externalId,
  transitionalCare,
  userId,
}: {
  careseekerName: string;
  externalId: string;
  transitionalCare: Readonly<TransitionalCare> | undefined;
  userId: string;
}) => {
  const translations = useTranslations();
  const createTransitionalCarePdf = useCreateTransitionalCarePdf();
  const { handleOpenPdf, handleSendToHis, progressOpenPdf, progressSendToHis } =
    useDocumentHandling({
      createPDFDocument: createTransitionalCarePdf,
      careseekerName,
      externalId,
      userId,
    });

  if (!transitionalCare) return null;

  return (
    <DownloadSectionWrapper
      body={
        translations.patientForms.transitionalCareForm.downloadSection.bodyText
      }
      lastUpdated={transitionalCare.updated_at!}
      onClickPrint={() => handleOpenPdf({ transitionalCare })}
      onClickSendToHis={() => handleSendToHis({ transitionalCare })}
      pdfName={DOWNLOADED_PDF_NAME}
      progressSendToHis={progressSendToHis}
      progressOpenPdf={progressOpenPdf}
      title={
        translations.patientForms.transitionalCareForm.downloadSection.title
      }
    />
  );
};
