import { Theme } from "@mui/material/styles";
import { handleKeyDown } from "core/model/accessibility/keyboardActions";
import { isNewTheme } from "core/model/utils/featureFlags";
import {
  CUSTOM_BLACK,
  MEDIUM_GREY,
  PRIMARY_COLOR,
  TEXT_ACCENT,
  TEXT_ACCENT_DARK,
  TEXT_DARK_PRIMARY,
  TEXT_DARK_SECONDARY,
  TEXT_DISABLED_COLOR,
  TEXT_LIGHT_SECONDARY,
  WHITE,
} from "ds_legacy/materials/colors";
import {
  dp,
  margin,
  padding,
  rem,
  responsiveCSS,
  sizing,
} from "ds_legacy/materials/metrics";
import { focusOutlineStyles } from "ds_legacy/styles";
import React, { CSSProperties } from "react";
import styled from "styled-components";

/* Font Family */
export const FONT_FAMILY = isNewTheme()
  ? "Mulish, sans-serif"
  : "Lato, Open Sans, Helvetica Neue, Helvetica, sans-serif";

/* Font Weight */
export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_SEMI_BOLD = 600;
export const FONT_WEIGHT_BOLD = 700;

/* Font Size */
export const FONT_SIZE_34 = rem(34);
export const FONT_SIZE_32 = rem(32);
export const FONT_SIZE_30 = rem(30);
export const FONT_SIZE_24 = rem(24);
export const FONT_SIZE_22 = rem(22);
export const FONT_SIZE_20 = rem(20);
export const FONT_SIZE_18 = rem(18);
export const FONT_SIZE_17 = rem(17);
export const FONT_SIZE_16 = rem(16);
export const FONT_SIZE_15 = rem(15);
export const FONT_SIZE_14 = rem(14);
export const FONT_SIZE_13 = rem(13);
export const FONT_SIZE_12 = rem(12);
export const FONT_SIZE_11 = rem(11);
export const FONT_SIZE_10 = rem(10);
export const FONT_SIZE_9 = rem(9);
export const FONT_SIZE_8 = rem(8);
export const FONT_SIZE_5 = rem(5);

//Mobile Only
export const FONT_SIZE_28 = rem(28);
export const FONT_SIZE_19 = rem(19);

/* Line Height */
export const LINE_HEIGHT_40 = rem(40);
export const LINE_HEIGHT_32 = rem(32);
export const LINE_HEIGHT_28 = rem(28);
export const LINE_HEIGHT_24 = rem(24);
export const LINE_HEIGHT_18 = rem(18);
export const LINE_HEIGHT_16 = rem(16);
//Mobile Only
export const LINE_HEIGHT_34 = rem(34);
export const LINE_HEIGHT_20 = rem(20);
export const LINE_HEIGHT_14 = rem(14);

/* Text Color Config  */
type ColorsProps = {
  accent?: boolean;
  color?: string;
  dark?: boolean;
  disabled?: boolean;
  error?: boolean;
  light?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  unfocused?: boolean;
};

export function configureTextColor(mainColor: string) {
  return function getTextColor(
    props: ColorsProps & {
      theme: Theme;
    },
  ) {
    if (props.color) {
      return props.color;
    } else if (props.primary) {
      if (props.dark === true) return props.theme.palette.primary.dark;
      if (props.light === true) return props.theme.palette.primary.light;
      return props.theme.palette.primary.main;
    } else if (props.accent) {
      if (props.dark === true) return TEXT_ACCENT_DARK;
      return TEXT_ACCENT;
    } else if (props.light) {
      if (props.unfocused) return TEXT_LIGHT_SECONDARY;
      return WHITE;
    } else if (props.success) {
      return props.theme.palette.success.main;
    } else if (props.error) {
      return props.theme.palette.error.main;
    } else if (props.disabled) {
      return TEXT_DISABLED_COLOR;
    } else if (props.secondary) {
      return TEXT_DARK_SECONDARY;
    }
    return mainColor;
  };
}

/* Styled Typography"*/
export const Display = styled.h1<ColorsProps & { margin?: string }>`
  font-family: ${FONT_FAMILY};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: ${FONT_SIZE_34};
  line-height: ${LINE_HEIGHT_40};
  color: ${configureTextColor(MEDIUM_GREY)};
  margin: ${(props) => props.margin || margin(2)};
`;

export const Heading = styled.h1<
  ColorsProps & {
    bold?: boolean;
    display?: CSSProperties["display"];
    fullWidth?: boolean;
    margin?: CSSProperties["margin"] | false;
    overflow?: CSSProperties["overflow"];
    overflowWrap?: CSSProperties["overflowWrap"];
    textOverflow?: CSSProperties["textOverflow"];
    whiteSpace?: CSSProperties["whiteSpace"];
    width?: CSSProperties["width"];
  }
>`
  font-family: ${FONT_FAMILY};
  font-size: ${FONT_SIZE_24};
  line-height: ${LINE_HEIGHT_32};
  color: ${configureTextColor(TEXT_DARK_PRIMARY)};
  margin: ${(props) => props.margin || margin(1, 2)};
  display: ${(props) => props.display || "block"};
  white-space: ${(props) => props.whiteSpace || "normal"};
  overflow-wrap: ${(props) => props.overflowWrap};
  overflow: ${(props) => props.overflow};
  text-overflow: ${(props) => props.textOverflow};
  font-weight: ${(props) => (props.bold ? "bold" : FONT_WEIGHT_REGULAR)};
  width: ${(props) => (props.fullWidth ? "100%" : props.width)};
`;

type TitleProps = ColorsProps & {
  alignSelf?: CSSProperties["alignSelf"];
  bold?: boolean;
  margin?: string;
  ornament?: boolean;
  textAlign?: string;
  whiteSpace?: string;
  width?: string;
};

export const Title = styled.h1<TitleProps>`
  align-self: ${(props) => props.alignSelf || undefined};
  font-family: ${FONT_FAMILY};
  width: ${(props) => props.width};
  font-weight: ${(props) =>
    props.bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_MEDIUM};
  font-size: ${FONT_SIZE_20};
  line-height: ${LINE_HEIGHT_24};
  text-align: ${(props) => props.textAlign || "start"};
  color: ${configureTextColor(TEXT_DARK_PRIMARY)};
  white-space: ${(props) => props.whiteSpace || "normal"};
  margin: ${(props) => props.margin || margin(2)};
  @media print {
    & {
      font-size: ${FONT_SIZE_22};
      margin: ${margin(1, 2)};
    }
  }
  ${({ ornament }) =>
    ornament
      ? responsiveCSS({
          mobile: [
            `padding:${padding(0, 0.5)}`,
            `margin:${margin(2, 2, 1, 2)}`,
          ],
        })
      : ""}
`;

export const LongMenuText = styled.span`
  font-size: ${FONT_SIZE_14};
  line-height: ${LINE_HEIGHT_24};
  color: ${CUSTOM_BLACK};
`;

export const Subheading = styled.h2<
  ColorsProps & {
    bold?: boolean;
    display?: string;
    fullWidth?: boolean;
    margin?: CSSProperties["margin"];
    overflow?: string;
    textAlign?: string;
    textOverflow?: string;
    whiteSpace?: string;
    width?: string;
  }
>`
  font-family: ${FONT_FAMILY};
  font-size: ${FONT_SIZE_16};
  line-height: ${LINE_HEIGHT_28};
  color: ${configureTextColor(TEXT_DARK_PRIMARY)};
  margin: ${(props) => props.margin || margin(1, 2)};
  display: ${(props) => props.display || "block"};
  white-space: ${(props) => props.whiteSpace || "normal"};
  overflow: ${(props) => props.overflow};
  text-align: ${(props) => props.textAlign || "start"};
  text-overflow: ${(props) => props.textOverflow};
  font-weight: ${(props) => (props.bold ? "bold" : FONT_WEIGHT_REGULAR)};
  width: ${(props) => (props.fullWidth ? "100%" : props.width)};
  @media print {
    & {
      margin: ${margin(0.5, 2)};
    }
  }
`;

export const Tab = styled.span<
  ColorsProps & {
    fontSize?: CSSProperties["fontSize"];
    lineHeight?: CSSProperties["lineHeight"];
    selected?: boolean;
    uppercase?: boolean;
  }
>`
  font-family: ${FONT_FAMILY};
  font-weight: ${({ selected }) =>
    selected ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR};
  font-size: ${({ fontSize }) => fontSize ?? FONT_SIZE_14};
  line-height: ${({ lineHeight }) => lineHeight ?? undefined};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : undefined)};
  color: ${configureTextColor(TEXT_LIGHT_SECONDARY)};
  max-width: 50em;
  margin: ${margin(0)};
  user-select: none;
`;

export const EventChip = styled.span`
  font-family: ${FONT_FAMILY};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: ${FONT_SIZE_10};
  color: ${WHITE};
  margin: ${margin(0)};
  white-space: nowrap;
  user-select: none;
`;

export type BodyProps = ColorsProps & {
  alignItems?: CSSProperties["alignItems"];
  breakWord?: boolean;
  children?: React.ReactNode;
  color?: CSSProperties["color"];
  cursor?: CSSProperties["cursor"];
  display?: CSSProperties["display"];
  flex?: CSSProperties["flex"];
  fontSize?: CSSProperties["fontSize"];
  fontStyle?: CSSProperties["fontStyle"];
  fontWeight?: CSSProperties["fontWeight"];
  height?: CSSProperties["height"];
  lineHeight?: CSSProperties["lineHeight"];
  margin?: CSSProperties["margin"];
  maxWidth?: CSSProperties["maxWidth"];
  minWidth?: CSSProperties["minWidth"];
  overflow?: CSSProperties["overflow"];
  textAlign?: CSSProperties["textAlign"];
  textOverflow?: CSSProperties["textOverflow"];
  textTransform?: CSSProperties["textTransform"];
  whiteSpace?: CSSProperties["whiteSpace"];
  width?: CSSProperties["width"];
} & React.HTMLAttributes<HTMLSpanElement>;

const shouldForwardProp = (
  prop: PropertyKey,
  defaultValidatorFn: (propName: keyof BodyProps) => boolean,
) => {
  // List of props we do not want to forward to the DOM
  const customProps: (keyof BodyProps)[] = [
    "alignItems",
    "cursor",
    "display",
    "flex",
    "fontSize",
    "fontStyle",
    "fontWeight",
    "height",
    "lineHeight",
    "margin",
    "maxWidth",
    "minWidth",
    "overflow",
    "textAlign",
    "textOverflow",
    "textTransform",
    "whiteSpace",
    "width",
    "breakWord",
    "error",
  ];

  // Check if the prop is not a custom prop and use the default validation function
  return (
    !customProps.includes(prop as keyof BodyProps) &&
    defaultValidatorFn(prop as keyof BodyProps)
  );
};

export const Body = styled.span.withConfig<BodyProps>({ shouldForwardProp })`
  align-items: ${(props) => props.alignItems || "top"};
  color: ${configureTextColor(TEXT_DARK_PRIMARY)};
  cursor: ${(props) => props.cursor || undefined};
  display: ${(props) => props.display || "block"};
  flex: ${(props) => props.flex};
  font-family: ${FONT_FAMILY};
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : isNewTheme() ? FONT_SIZE_14 : FONT_SIZE_13};
  font-style: ${(props) => props.fontStyle || "normal"};
  font-weight: ${({ fontWeight }) =>
    fontWeight
      ? fontWeight
      : isNewTheme()
      ? FONT_WEIGHT_REGULAR
      : FONT_WEIGHT_MEDIUM};
  height: ${(props) => props.height || "auto"};
  line-height: ${({ lineHeight }) =>
    lineHeight ? lineHeight : isNewTheme() ? LINE_HEIGHT_18 : LINE_HEIGHT_24};
  margin: ${(props) => props.margin || margin(0, 2)};
  max-width: ${(props) => props.maxWidth || "50em"};
  min-width: ${(props) => props.minWidth};
  overflow: ${(props) => props.overflow};
  text-align: ${(props) => props.textAlign};
  text-overflow: ${(props) => props.textOverflow};
  text-transform: ${(props) => props.textTransform || "none"};
  white-space: ${(props) => props.whiteSpace || "normal"};
  width: ${(props) => props.width || undefined};
  word-wrap: ${({ breakWord }) => (breakWord ? "break-word" : undefined)};
  @media print {
    & {
      font-size: ${FONT_SIZE_15};
      overflow: visible;
      white-space: normal;
      word-wrap: break-word;
    }
  }
`;

export const Chip = styled.span<
  ColorsProps & {
    bold?: boolean;
    fontSize?: string;
    margin?: string;
    textBreak?: boolean;
    userSelect?: boolean;
  }
>`
  font-family: ${FONT_FAMILY};
  font-weight: ${({ bold }) => (bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR)};
  font-size: ${({ fontSize }) => fontSize || FONT_SIZE_13};
  color: ${configureTextColor(TEXT_DARK_PRIMARY)};
  margin: ${(props) => props.margin || margin(0.5, 1)};
  text-overflow: ${({ textBreak }) => (textBreak ? "ellipsis" : "")};
  white-space: ${({ textBreak }) => (textBreak ? "nowrap" : "")};
  overflow: ${({ textBreak }) => (textBreak ? "hidden" : "")};
  max-width: ${({ textBreak }) => (textBreak ? sizing(35) : "50em")};
  user-select: ${({ userSelect }) => (userSelect ? "inherit" : "none")};
`;

export type CaptionProps = ColorsProps & {
  bold?: boolean;
  color?: string;
  fontSize?: string;
  fontStyle?: string;
  lineHeight?: CSSProperties["lineHeight"];
  margin?: string;
  maxWidth?: string;
  padding?: CSSProperties["padding"];
  textAlign?: string;
  whiteSpace?: CSSProperties["whiteSpace"];
};

export const Caption = styled.span<CaptionProps>`
  color: ${({ color }) => color || configureTextColor(TEXT_DARK_SECONDARY)};
  font-family: ${FONT_FAMILY};
  font-size: ${({ fontSize }) => fontSize || FONT_SIZE_12};
  font-style: ${({ fontStyle }) => fontStyle || "normal"};
  font-weight: ${({ bold }) => (bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR)};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin: m }) => m || margin(0, 2)};
  padding: ${({ padding }) => padding};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
  text-align: ${({ textAlign }) => textAlign || "start"};
  white-space: ${({ whiteSpace }) => whiteSpace || "nowrap"};
`;

export const Label = styled.span<
  ColorsProps & {
    bold?: boolean;
    color?: string;
    margin?: string;
    wrapText?: boolean;
  }
>`
  font-family: ${FONT_FAMILY};
  font-weight: ${(props) =>
    props.bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR};
  font-size: ${FONT_SIZE_12};
  text-transform: uppercase;
  color: ${(props) => props.color || configureTextColor(TEXT_DARK_SECONDARY)};
  margin: ${(props) => props.margin || margin(0, 2)};
  overflow: ${({ wrapText }) => (wrapText ? "" : "hidden")};
  text-overflow: ${({ wrapText }) => (wrapText ? "" : "ellipsis")};
  white-space: ${({ wrapText }) => (wrapText ? "inherit" : "nowrap")};
  text-align: ${({ wrapText }) => (wrapText ? "right" : "left")};
`;

export const Badge = styled.span<
  ColorsProps & { color?: string; large?: boolean }
>`
  font-family: ${FONT_FAMILY};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: ${({ large }) => (large ? FONT_SIZE_13 : FONT_SIZE_12)};
  color: ${(props) =>
    configureTextColor(props.color || TEXT_DARK_PRIMARY)(props)};
  margin: ${margin(0)};
  white-space: nowrap;
  user-select: none;
`;

export const Email = styled.a<{
  fontWeight?: number;
  margin?: string;
  print?: boolean;
  textBreak?: boolean;
  width?: number;
}>`
  display: table;
  margin: ${(props) => props.margin || margin(0, 2)};
  text-decoration: none;
  color: ${TEXT_DARK_PRIMARY};
  font-family: ${FONT_FAMILY};
  font-size: ${(props) => (props.print ? FONT_SIZE_15 : FONT_SIZE_13)};
  font-weight: ${({ fontWeight }) => fontWeight || FONT_WEIGHT_REGULAR};
  line-height: ${LINE_HEIGHT_24};
  font-style: normal;
  text-overflow: ${({ textBreak }) => (textBreak ? "ellipsis" : "")};
  white-space: ${({ textBreak }) => (textBreak ? "nowrap" : "")};
  overflow: ${({ textBreak }) => (textBreak ? "hidden" : "")};
  display: ${({ textBreak }) => (textBreak ? "block" : "")};
  max-width: ${({ textBreak, width }) =>
    textBreak ? dp(width ? width - 80 : 200) : width ? width : "auto"};
  &:visited {
    color: ${TEXT_DARK_PRIMARY};
  }
  &:hover {
    color: ${(props) =>
      props.theme?.palette?.primary.main ?? TEXT_DARK_SECONDARY};
  }
`;

type LinkProps = ColorsProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    disabled?: boolean;
    fontSize?: string;
    fontWeight?: number;
    margin?: string;
  };

const StyledLink = styled.a<LinkProps>`
  font-family: ${FONT_FAMILY};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : undefined)};
  color: ${({ color }) => color || configureTextColor(PRIMARY_COLOR)};
  margin: ${(props) => props.margin || margin(0)};
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  cursor: ${({ disabled }) => (disabled ? undefined : "pointer")};
  text-decoration: none;
  padding: ${padding(0.25)};
  &:hover,
  &:focus-visible {
    text-decoration: ${({ disabled }) => (disabled ? undefined : "underline")};
    text-decoration-color: ${({ color }) =>
      color || configureTextColor(PRIMARY_COLOR)};
  }
  ${focusOutlineStyles()}
`;

export const Link = ({ disabled, onClick, ...props }: LinkProps) => {
  const handleClick = disabled ? () => null : onClick;
  return (
    <StyledLink
      onClick={handleClick}
      onKeyDown={handleKeyDown({
        onConfirm: handleClick as unknown as (
          event: React.KeyboardEvent<Element>,
        ) => void,
      })}
      tabIndex={0}
      {...props}
    />
  );
};
