import { Avatar } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  CheckCircleIcon,
  DirectionsOutlinedIcon,
  MailIcon,
  MailOpenIcon,
  NotificationsIcon,
} from "ds/icons";
import { BRAND_PRIMARY_MAIN_COLOR } from "ds_legacy/materials/colors";
import { ROUND_BORDER_RADIUS, dp, space } from "ds_legacy/materials/metrics";
import { SHADOW_DP1, SHADOW_DP2 } from "ds_legacy/materials/shadows";
import {
  Badge as BadgeText,
  FONT_SIZE_16,
} from "ds_legacy/materials/typography";
import { ReactNode } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import InfoChip from "../InfoChip";

export type BadgeProps = {
  backgroundColor?: string;
  children: ReactNode;
  color?: string;
  large?: boolean;
  offset?: boolean;
  position?: string;
  shadow?: string;
};

export const BadgeContainer = styled.div<{ margin?: string }>`
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : "initial")};
`;

export const BadgeElement = styled.span<{
  backgroundColor?: string;
  large?: boolean;
  offsetAmount?: boolean;
  position?: string;
  shadow?: string;
}>`
  position: ${({ position }) => (position ? position : "absolute")};
  top: ${(props) => (props.offsetAmount ? space(-1) : space(0))};
  right: ${(props) => (props.offsetAmount ? space(-2.5) : space(0))};
  border-radius: ${ROUND_BORDER_RADIUS};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  height: ${({ large }) => (large ? dp(24) : dp(22))};
  width: ${({ large }) => (large ? dp(24) : dp(22))};
  pointer-events: none;
  box-shadow: ${({ shadow }) => shadow};
`;

export default function Badge({
  backgroundColor,
  children,
  color,
  large,
  offset,
  position,
  shadow,
}: BadgeProps) {
  if (children != null && children !== 0)
    return (
      <BadgeElement
        offsetAmount={offset}
        large={large}
        shadow={shadow}
        backgroundColor={backgroundColor}
        position={position}
      >
        <BadgeText color={color}>{children}</BadgeText>
      </BadgeElement>
    );
  return null;
}

export function BellBadge({ isHover }: { isHover: boolean | undefined }) {
  const theme = useTheme();
  return (
    <Badge shadow={isHover ? SHADOW_DP2 : SHADOW_DP1} large>
      <NotificationsIcon
        style={{
          fontSize: FONT_SIZE_16,
          color: theme.palette.common.black,
        }}
        size={FONT_SIZE_16}
      />
    </Badge>
  );
}

export const NewBadge = ({ marginWrapper }: { marginWrapper?: string }) => {
  const translations = useTranslations();
  return (
    <InfoChip
      color="secondary"
      variant="filled"
      label={translations.general.new.toUpperCase()}
      margin={marginWrapper}
    />
  );
};

export function ProviderSearchRequestBadge() {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        background: BRAND_PRIMARY_MAIN_COLOR,
        padding: dp(4),
        boxSizing: "border-box",
      }}
    >
      <DirectionsOutlinedIcon
        data-testid="direct-request-badge"
        style={{ color: theme.palette.common.white, width: "100%" }}
        size={FONT_SIZE_16}
      />
    </Avatar>
  );
}

export const MessageCountBadge = ({
  isActionRequiredByUser,
  label,
  style,
}: {
  isActionRequiredByUser: boolean;
  label: number | string;
  style?: React.CSSProperties;
}) => {
  const icon = isActionRequiredByUser ? (
    <MailIcon size={FONT_SIZE_16} />
  ) : (
    <MailOpenIcon size={FONT_SIZE_16} />
  );
  const color = isActionRequiredByUser ? "secondary" : "primary";
  const variant = isActionRequiredByUser ? "filled" : "outlined";
  return (
    <div style={style}>
      <InfoChip
        testId="message-count-badge"
        color={color}
        icon={icon}
        label={label}
        variant={variant}
      />
    </div>
  );
};

export const AcceptedBadge = ({ label }: { label: number | string }) => {
  const translations = useTranslations();
  return (
    <InfoChip
      color="secondary"
      icon={<CheckCircleIcon size={FONT_SIZE_16} />}
      label={label}
      tooltip={translations.search.accepted}
      sx={{ boxShadow: SHADOW_DP1 }}
    />
  );
};

export const ReverseSearchBadge = ({
  isProviderSearchRequest,
  marginWrapper,
}: {
  isProviderSearchRequest?: boolean;
  marginWrapper?: string;
}) => {
  const translations = useTranslations();
  return (
    <InfoChip
      color={isProviderSearchRequest ? undefined : "primary"}
      variant="outlined"
      label={
        translations.careproviderApp.settings.reversePatientSearch
          .chipBasedOnSearch
      }
      margin={marginWrapper}
      sx={{
        color: isProviderSearchRequest ? BRAND_PRIMARY_MAIN_COLOR : undefined,
        borderColor: isProviderSearchRequest
          ? BRAND_PRIMARY_MAIN_COLOR
          : undefined,
      }}
    />
  );
};
